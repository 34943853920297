@charset "utf-8";

// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Rubik:400,700');


// Update some of Bulma's component variables
$body-background-color: #fff;
$body-family: 'Rubik';

// Import only what you need from Bulma
@import '../node_modules/bulma/sass/utilities/_all.sass';
@import '../node_modules/bulma/sass/base/_all.sass';

@import '../node_modules/bulma/sass/grid/_all.sass';

@import '../node_modules/bulma/sass/elements/box.sass';
@import '../node_modules/bulma/sass/elements/button.sass';
@import '../node_modules/bulma/sass/elements/container.sass';
@import '../node_modules/bulma/sass/elements/form.sass';
@import '../node_modules/bulma/sass/elements/title.sass';

@import '../node_modules/bulma/sass/components/navbar.sass';
@import '../node_modules/bulma/sass/components/card.sass';
@import '../node_modules/bulma/sass/components/modal.sass';

@import '../node_modules/bulma/sass/layout/hero.sass';
@import '../node_modules/bulma/sass/layout/section.sass';


// Custom Styles

.navbar, .navbar-menu {
  background-color: #870A17; }
.navbar-item, .navbar-burger, .navbar-item>a {
  color: #fff; }
a.navbar-item:hover, a.navbar-item:active, a.navbar-item:focus {
  color: #CACACA; }

.navbar-brand>.navbar-item {
  font-size: 18pt; }
.navbar-item > a {
  text-transform: uppercase; }

.app-body {
  line-height: 1.2;

  h1 {
    font-size: 14pt;
    font-weight: bold; }

  .app-brand {
    font-size: 16pt;
    font-weight: bold;
    color: #870A17;
    text-transform: uppercase;
    margin-bottom: 1rem; }

  .card-content {
    h1 {
      margin-bottom: 10px; } }

  .item.card {
    height: 200px;
    overflow-y: hidden; } }

.pageHeader {
  margin-bottom: 22px;
  h1 {
    font-size: 22pt; } }

.clickable-card:hover {
  cursor: pointer; }

// Article Specific Styles
.article-page {
  h1 {
    font-size: 2.5rem; }
  h2 {
    font-size: 2rem; }
  h3 {
    font-size: 1.75rem; }
  h4 {
    font-size: 1.5rem; }
  h1,h2,h3,h4 {
    font-weight: 500;
    margin-bottom: 0.5rem; }

  .article-content {
    line-height: 1.2;
    font-weight: 400;
    p {
      margin-bottom: 16px; }
    blockquote {
      padding: 5px 15px;
      background-color: rgba(229, 235, 232, 1.0);
      margin-bottom: 16px;
      p {
        margin: 0px; } }
    a {
      color: rgba(93, 135, 118, 1.0);
      text-decoration: underline; }
    h1,h2,h3,h4,h5 {
      margin-bottom: 8px; }
    h2 {
      color: rgba(68, 121, 100, 1.0); }
    h4 {
      font-size: 1.5rem;
      margin-bottom: 8px;
      font-weight: 500; }
    ul,ol {
      margin-bottom: 16px;
      li {
        margin-top: 10px; }
      li > p {
        display: inline; } }
    ol {
      list-style-position: inside; }
    ul {
      list-style: inside; } }

  .article-up-next {
    margin-top: 20px;
    padding-top: 30px;
    border-top: 1px dashed #265B5F; } }

.loading-container {
  min-height: 400px; }

// Glossary Specific Modal Styles

.glossary.modal {
  .modal-content {
    padding: 10px;

    .box {
      min-height: 100px;
      padding: 10px; } } }
